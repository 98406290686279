<template>
	<div class="gonghai">
		<div class="top1">
			<div class="box1" @click="chooseClues(1)" v-if="permissionList.includes('myChanceManage') || isAdmin">
				<div class="box2">
					<p :class="{'activep': cluesIndex === 1}">我的商机</p>
					<span :class="{'activespan': cluesIndex === 1}">（{{myCluesCount}}）</span>
				</div>
				<div class="box3" v-if="cluesIndex === 1"></div>
			</div>
			<div class="box1" @click="chooseClues(2)" v-if="permissionList.includes('teamChanceManage') || isAdmin">
				<div class="box2">
					<p :class="{'activep': cluesIndex === 2}">团队商机</p>
					<span :class="{'activespan': cluesIndex === 2}">（{{teamCluesCount}}）</span>
				</div>
				<div class="box3" v-if="cluesIndex === 2"></div>
			</div>
		</div>
		<div class="tablebox">
			<div class="tabletop">
				<div class="inputbox">
					<input placeholder="公司名称搜索" v-model="searchInfo.customerName"/>
					<div class="flex"><i class="el-icon-search" style="color: #AAAAAA;"></i></div>
				</div>
				<div class="selectbox">
					<span>公司类型</span>
					<el-select v-model="searchInfo.companyType" placeholder="请选择">
						<el-option
							v-for="item in companyTypeList"
							:key="item.settingId"
							:label="item.settingName"
							:value="item.settingId"
						></el-option>
					</el-select>
				</div>
				<div class="chaxun nochoosetext flex" style="margin-left: 2.5em;" @click="_customerList">
					<span>查询</span>
				</div>
				<div class="chaxun nochoosetext flex" @click="clickReset"><span>重置</span></div>
			</div>

			<div class="allcountbox">
				共筛选出 <span style="color: #3266F7;">{{totalCount}}</span> 条商机记录
			</div>
			
			<div class="tablecontentbox">
				<el-table :data="tableData" border @row-click="clickRow">
					<el-table-column type="index" label="序号" width="80"></el-table-column>
					<el-table-column prop="customerName" label="公司名称"></el-table-column>
					
					<el-table-column label="联系人">
						<template slot-scope="scope">
							<div >
								<div class="" v-for="(item,index) in scope.row.contactVo" :key="index">
									姓名: {{item.userName}}  电话: {{item.contactPhone || '暂无'}}
								</div>
							</div>
							
						</template>
						
					</el-table-column>
					<el-table-column prop="companyIndustryName" label="所属行业"></el-table-column>
					<!-- <el-table-column prop="companyTypeName" label="公司类型"></el-table-column> -->
					<!-- <el-table-column prop="address" label="位置信息">
						<template slot-scope="scope">
							<div class="textbox">
								{{scope.row.address}}
							</div>
						</template>
						
					</el-table-column> -->
					<el-table-column prop="customerDesc" label="备注">
						<template slot-scope="scope">
							<div class="textbox">
								{{scope.row.customerDesc}}
							</div>
						</template>
					</el-table-column>
			<!-- 		<el-table-column prop="zip" label="需求描述" width="150"></el-table-column>
					<el-table-column prop="zip" label="类型" width="150"></el-table-column> -->
					<el-table-column label="操作" width="150" v-if="searchInfo.type === '1'">
						<template slot-scope="scope">
							<div class="twobtn">
								<div class="kanbox" @click.stop="goDetail(scope.row)">
									<img src="../../assets/btnicon/image7.png" >
									看
								</div>
							</div>
						</template>
					</el-table-column>
					<el-table-column label="操作" width="330" v-if="searchInfo.type === '0'">
						<template slot-scope="scope">
							<div class="twobtn">
								<div class="kanbox" @click.stop="goDetail(scope.row)">
									<img src="../../assets/btnicon/image7.png" >
									看
								</div>
								<div class="kanbox gen" @click.stop="showAddGen(scope.row)" v-if="permissionList.includes('myChanceManage:addGenjin') || isAdmin">
									<img src="../../assets/btnicon/image2.png" >
									跟
								</div>
								<div class="kanbox gai" @click.stop="_customerInfo(scope.row.customerId)" v-if="permissionList.includes('myChanceManage:edit') || isAdmin">
									<img src="../../assets/btnicon/image3.png" >
									改
								</div>
							<!-- 	<div class="kanbox" style="margin-left: 1.25em;">
									<img src="../../assets/btnicon/image4.png" >
									转
								</div>
								<div class="kanbox zeng" @click="clickDiu(scope.row.customerId)" v-if="permissionList.includes('myChanceManage:zhuan')">
									<img src="../../assets/btnicon/image5.png" >
									丢
								</div> -->
								<div class="kanbox shan" @click.stop="clickDeletl(scope.row.customerId)" v-if="isAdmin">
									<img src="../../assets/btnicon/image6.png" >
									删
								</div>
							</div>
						</template>
					</el-table-column>
				</el-table>
				
				<div class="pagebox">
					<el-pagination
						background
						@size-change="handleSizeChange"
						@current-change="handleCurrentChange"
						:current-page="searchInfo.page"
						:page-sizes="[5, 10, 15, 20]"
						:page-size="searchInfo.pageSize"
						layout="total, sizes, prev, pager, next, jumper"
						:total="totalCount"
					></el-pagination>
				</div>
			</div>
		</div>
		<add-genjin @updataInfo="_customerList" ref="addGenjin"></add-genjin>
		<!-- <add-clues ref="addClues"></add-clues> -->
		<add-shangji @updataInfo="_customerList" ref="addShangji"></add-shangji>
		<edit-shangji @updataInfo="_customerList" ref="editShangji"></edit-shangji>
		<delete-popup @deleteInfo="_deleteCustomer" ref="deletePopup" message="确认要删除这条商机吗？"></delete-popup>
		<delete-popup @deleteInfo="_shiftCustomerToSea" ref="zhuanSea" message="确认要转入公海吗？"></delete-popup>
	</div>
</template>

<script>
	import  {customerList,customerInfo,deleteCustomer,shiftCustomerToSea} from "@/network/api.js"
	import { mapState } from "vuex"
	import addShangji from "./popup/addShangji.vue"
	import addGenjin from "./popup/addGenjin.vue"
	import editShangji from "./popup/editShangji.vue"
	import deletePopup from "@/components/common/deletePopup.vue"
export default {
	data() {
		return {
			cluesIndex: 1,
			totalCount: 0,
			deleteId: '',
			myCluesCount: 0,
			teamCluesCount: 0,
			searchInfo: {
				startTime: '',
				endTime: '',
				customerSource: '',
				customerStatus: '',
				isWait: '0',
				companyType: '',
				customerName: '',
				isAsc: 'asc',
				inSea: '0',
				orderByColumn: '',
				page: 1,
				pageSize: 10,
				searchBody: '',
				status: 1,   //0 线索(跟进中的客户) 1 商机(添加商机的客户) 2 客户(已合作的客户)
				type: '0'   // 0 我的 1 团队
			},
			companyList: [
				{
					value: '阿里',
					lable: '阿里'
				}
			],
			company: '',
			tableData: []
		};
	},
	components: {
		addShangji,
		addGenjin,
		editShangji,
		deletePopup
	},
	computed: {
		// ...mapState(['companyTypeList'])
		...mapState('systemModule',{
			companyTypeList: 'companyTypeList',
		}),
		permissionList() {
			return this.$store.state.permissionList 
		},
		isAdmin() {
			return (this.$store.state.isadmin && localStorage.getItem('isStaff') == '0')
		},
	},
	name: 'shangjiManage',
	created() {
		if (this.$route.query.startTime && this.$route.query.endTime) {
			this.searchInfo.startTime = this.$route.query.startTime
			this.searchInfo.endTime = this.$route.query.endTime
		}
		this._customerList()
		this.getMyCluesCount()
		this.getTeamCluesCount()
	},
	methods: {
		// 点击行数据
		clickRow(val) {
			// if (this.permissionList.includes('gonghaiManage:detail') || this.isAdmin) {
				this.goDetail(val)
			// }
		},
		// 获取我的线索数量
		async getMyCluesCount() {
			let info = JSON.parse(JSON.stringify(this.searchInfo))
			info.type = '0'
			const res = await customerList(info)
			this.myCluesCount = res.data.list.length
		},
		// 获取团队线索数量
		async getTeamCluesCount() {
			let info = JSON.parse(JSON.stringify(this.searchInfo))
			info.type = 1
			const res = await customerList(info)
			this.teamCluesCount = res.data.list.length
		},
		// 点击丢，转入公海
		clickDiu(id) {
			this.deleteId = id
			this.$refs.zhuanSea.dialogVisible = true
		},
		// 转入公海
		async _shiftCustomerToSea() {
			const res = await shiftCustomerToSea({
				customerId: this.deleteId
			})
			this.$myMessage('转入成功','success')
			this.$refs.zhuanSea.dialogVisible = false
			this._customerList()
			this.deleteId = ''
		},
		// 获取我的线索数量
		async getYyCluesCount() {
			let info = JSON.parse(JSON.stringify(this.searchInfo))
			info.type = '0'
			const res = await customerList(info)
			this.myCluesCount = res.data.list.length
		},
		// 获取团队线索数量
		async getTeamCluesCount() {
			let info = JSON.parse(JSON.stringify(this.searchInfo))
			info.type = 1
			const res = await customerList(info)
			this.teamCluesCount = res.data.list.length
		},
		// 删除商机
		async _deleteCustomer() {
			const res = await deleteCustomer({
				customerId: this.deleteId
			})
			this.$myMessage('删除成功','success')
			this.$refs.deletePopup.dialogVisible = false
			this._customerList()
			this.deleteId = ''
		},
		// 点击重置
		clickReset() {
			this.searchInfo.companyType = ''
			this.searchInfo.customerName = ''
			this.searchInfo.searchBody = ''
		},
		// 获取商机详情
		async _customerInfo(customerId) {
			const res = await customerInfo({customerId})
			console.log('线索详情',res);
			let info = res.data
			this.$refs.editShangji.drawer = true
			this.$refs.editShangji.ruleForm = {
				address: info.address, //公司地址
				companyIndustry: info.companySetting.industryId, //所属行业id
				companyType: info.companySetting.typeId, //公司类型id
				contactParams: info.contactVo, //联系人
				customerDesc: info.customerDesc, // 公司简介
				customerId: info.customerId, // 客户id
				customerName: info.customerName, //公司名称
				customerSource: info.companySetting.sourceId, //线索来源
				customerStatus: info.statusId, //客户状态
				customerTag: info.companySetting.tagId, // 标签id
				latitude: '', // 纬度
				longitude: '' // 经度
			}
		},
		// 跳转线索详情
		goDetail(row) {
			// console.log(row);
			// return
			this.$router.push({
				path: "/shangjidetail",
				query: {
					customerId: row.customerId,
					type: this.searchInfo.type
				}
			})
		},
		// 获取线索列表
		async _customerList() {
			const res = await customerList(this.searchInfo)
			console.log("商机列表",res);
			this.tableData = res.data.list
			this.totalCount = res.data.totalCount
		},
		// 点击删除
		clickDeletl(id) {
			this.deleteId = id
			this.$refs.deletePopup.dialogVisible = true
		},
		// 点击出现修改弹框
		showEdit() {
			this.$refs.editShangji.drawer = true
		},
		// 点击添加跟进
		showAddGen(info) {
			this.$refs.addGenjin.addinfo.customerId = info.customerId
			this.$refs.addGenjin.dialogVisible = true
		},

		// 选择我的、团队商机
		chooseClues(index) {
			this.cluesIndex = index
			this.searchInfo.type = index-1+''
			this._customerList()
		},
		handleSizeChange(val) {
			this.searchInfo.pageSize = val
			this._customerList()
		},
		handleCurrentChange(val) {
			this.searchInfo.page = val
			this._customerList()
		}
	}
};
</script>

<style scoped lang="scss">
	.tablecontentbox {
		padding: 1.25em;
	}
	.allcountbox {
		width: 100%;
		background-color: #EFF3F6;
		height: 3.25em;
		padding: 0 1.25em;
		font-size: 1em;
		font-family: PingFang-SC-Medium, PingFang-SC;
		font-weight: 500;
		color: #5B6271;
		display: flex;
		align-items: center;
		span {
			margin: 0 0.3em;
		}
	}
	.allpersonal {
		color: #3266F7;
		max-height: 6.25em;
		overflow-y: scroll;
	}
	::-webkit-scrollbar {
		// display: none;
		width: 0.375em;
		// border-radius: 0.1875em;
		// height: 1.25em;
		background-color: rgb(255, 255, 255);
	}
	::-webkit-scrollbar-thumb {
		border-radius: 0.1875em;
		background-color: rgb(172, 172, 172);
	}
	.textbox {
		 text-overflow: -o-ellipsis-lastline;
		  overflow: hidden;
		  text-overflow: ellipsis;
		  display: -webkit-box;
		  -webkit-line-clamp: 2;
		  line-clamp: 2;
		  -webkit-box-orient: vertical;
	}
	.box3 {
		width: 2.5em;
		height: 0.1875em;
		background: #3286FF;
		border-radius: 0.125em;
		position: absolute;
		
		bottom: 0;
	}
	.box2 {
		display: flex;
		align-items: flex-end;
		p {
			font-size: 1rem;
			font-family: PingFangSC-Medium, PingFang SC;
			font-weight: 500;
			color: #666666;
			&.activep {
				color: #000000;
				font-size: 1.125em;
			}
		}
		span {
			font-size: 0.75em;
			&.activespan {
				color: #000000;
			}
		}
	}
	.box1 {
		cursor: pointer;
		margin: 0 1.25em 0 0;
		height: 100%;
		position: relative;
		display: flex;
		align-items: center;
		justify-content: center;
		flex-direction: column;
	}
	.top1 {
		border-bottom: 1px solid #EEEEEE;
		width: 100%;
		height: 3.25em;
		background-color: #ffffff;
		display: flex;
		align-items: center;
		padding: 0 1.0625em;
		
	}
	::v-deep {
		.pagebox {
			width: 100%;
			display: flex;
			justify-content: flex-end;
			margin: 6.25em 0 0 1.25em;
			padding: 0 1.25em 0 0;
			.el-input__inner {
				color: #666666;
				width: 3em;
				height: 2.25em;
				line-height: 2.25em;
			}
			.el-select {
				.el-input__inner {
					color: #666666;
					width: 8em;
					height: 2.25em;
					line-height: 2.25em;
				}
			}
			
			.el-input__icon {
				line-height: 2.25em;
			}
		}
	}
.kanbox:active {
	opacity: 0.6;
}
.kanbox {
	cursor: pointer;
	width: 3.625em;
	height: 1.625em;
	background: #0091ff;
	border-radius: 0.8125em;
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 0 0.675em;
	font-size: 0.875em;
	font-family: PingFang-SC-Medium, PingFang-SC;
	font-weight: 500;
	color: #ffffff;
	img {
		width: 0.9375em;
		height: 1.0625em;
	}
	&.shan {
		background-color: #B3BCC9;
		margin: 0 0 0 1.25em;
		img {
			width: 0.9375em;
			height: 1em;
		}
	}
	&.gen {
		background-color: #FA7E33;
		margin: 0 0 0 1.25em;
		img {
			width: 0.8125em;
			height: 1.0625em;
		}
	}
	&.gai {
		background-color: #3DC57C;
		margin: 0 0 0 1.25em;
		img {
			width: 0.875em;
			height: 0.875em;
		}
	}
	&.zeng {
		background-color: #9F7EFF;
		margin: 0 0 0 1.25em;
		img {
			width: 0.875em;
			height: 0.8125em;
		}
	}
	img {
		width: 0.9375em;
		height: 0.9375em;
	}
}
.twobtn {
	display: flex;
	align-items: center;
	justify-content: center;
}
::v-deep {
	.cell {
		text-align: center;
	}
	tr {
		font-size: 0.975em;
		font-family: PingFang-SC-Medium, PingFang-SC;
		font-weight: 400;
		color: #868e99;
	}
	th {
		background-color: #f6f7fb !important;
		font-size: 0.975em;
		font-family: PingFang-SC-Medium, PingFang-SC;
		font-weight: 500;
		color: #5b6271;
	}
}
thead {
}
.addbox:active {
	opacity: 0.6;
}
.addbox {
	margin: 1.5625em 0 1.25em 0;
	cursor: pointer;
	width: 6.75em;
	height: 2.25em;
	background: linear-gradient(270deg, #2d74f4 0%, #3286ff 100%);
	border-radius: 0.25em;
	display: flex;
	align-items: center;
	justify-content: center;
	span {
		font-size: 0.875rem;
		font-family: PingFangSC-Medium, PingFang SC;
		font-weight: 500;
		color: #ffffff;
		margin: 0 0 0 1.25em;
	}
}
::v-deep {
	.el-button {
		display: flex;
		align-items: center;
		justify-content: center;
	}
}
.chaxun:active {
	background-color: rgb(234,242,255);
	color: #2F79FF;
	border: 1px solid #2F79FF;
}
.chaxun {
	cursor: pointer;
	margin: 0 0 0 1.25em;
	width: 5.625em;
	height: 2.25em;
	border-radius: 0.25em;
	border: 1px solid #DDDDDD;
	font-weight: 400;
	color: #333333;
	font-family: PingFang-SC-Medium, PingFang-SC;
	span {
		font-size: 0.875em;
	}
}
.tabletop {
	display: flex;
	padding: 2em 1.25em 1.25em;
	width: 100%;
	align-items: center;
}
::v-deep {
	.el-input__inner {
		color: #666666;
		width: 10.5em;
		height: 2.25em;
		line-height: 2.25em;
	}
	.el-input__icon {
		line-height: 2.25em;
	}
}
.selectbox {
	margin: 0 0 0 2.5em;
	display: flex;
	align-items: center;
	span {
		font-size: 0.875em;
		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: 400;
		color: #333333;
		margin: 0 0.6875em 0 0;
	}
}
.tablebox {
	
	background-color: #ffffff;
	
}
.inputbox {
	display: flex;
	align-items: center;
	background: #ffffff;
	border-radius: 0.25em;
	border: 1px solid #e4e4e4;
	height: 2.25em;
	width: 12.5em;
	justify-content: space-between;
	input {
		outline: none;
		border: 0;
		width: 10.25em;
		font-size: 0.875em;
		padding: 0 0.375em 0 0.875em;
	}
	div {
		width: 2.25em;
		height: 2.25em;
		background: #faf9fb;
		border-radius: 0px 0.25em 0.25em 0px;
		border: 1px solid #e4e4e4;
	}
}

.gonghai {
	padding: 0.9375em;
}
@media screen and (max-width: 1920px) {
	.gonghai {
		font-size: 16px;
	}
}
@media screen and (max-width: 1720px) {
	.gonghai {
		font-size: 15px;
	}
}
@media screen and (max-width: 1520px) {
	.gonghai {
		font-size: 14px;
	}
}
@media screen and (max-width: 1320px) {
	.gonghai {
		font-size: 13px;
	}
}
@media screen and (max-width: 1120px) {
	.gonghai {
		font-size: 12px;
	}
}
</style>
